@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
.loading {
    font-family: "Roboto", Arial;
    color: whitesmoke;
}

.loading-container {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 2em;
    background-color: #34ebe1;
    border-radius: 1.2rem;
    min-width: 80vw;
    min-height: 70vh;
    width: 3em;
    height: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
}
